.mainBackColor1 {
	/* background: #FF4B04 !important; */
	/* background: linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%) !important; */
	background: linear-gradient(291deg, #6EB7F7 0%, #9492F3 100%) !important;
}

.mainBackColor2 {
	/* background: #FF4B04 !important; */
	background: #90C4FF !important;
}

.mainColor1 {
	/* color: #FF4B04 !important; */
	color: #90C4FF !important;
}

.mainFontColor {
	color: #373737 !important;
}

.mainFontLightColor {
	color: #666666 !important;
}

.mainFontLighterColor {
	color: #999999 !important;
}

.mainColor2 {
	color: #FF4B04 !important;
}

.mainColor3 {
	color: #CF4E65 !important;
}

.mainBorderColor1 {
	border-color: #FF4B04 !important;
}

.mainBorderColor2 {
	border-color: #0492FF !important;
}

.van-nav-bar {
	background: #ECBB34;
}

.van-tabbar-item {
	font-size: 14px;

}

.van-cell {
	font-size: 16px;
}

.van-icon-arrow-left:before {
	color: #fff;
}

.van-nav-bar__title {
	color: #fff;
}

.van-nav-bar__right {
	color: #fff;

}
.van-popover__action {
	padding: 0 12px;
}
span.van-radio__label {
	padding-right: 0.1rem;
	padding-top: 0.1rem;
}

.btnStyle {
	border: 0px;
	border-radius: 5px;

	margin: 0.3rem;
	width: 90%;


	background: #FF4B04;
}

.btnOpStyle {
	border: 0px;
	border-radius: 5px;
	margin: 0.1rem;
	width: 80%;
	background: #FF4B04;
}

.code_span {
	color: #ECBB34;
	white-space: nowrap;
	/*强制span不换行*/
	display: inline-block;
	/*将span当做块级元素对待*/
	width: 32px;
	/*限制宽度*/
	overflow: hidden;
	/*超出宽度部分隐藏*/
	text-overflow: ellipsis;
	/*超出部分以点号代替*/
	line-height: 0.9;
	/*数字与*/
}

.code_btn {
	background: #ECBB34;
	border: 0px;
	float: right;
}

.van-field__control {
	font-size: 16px;
}

.van-nav-bar__text {
	color: #fff;

}

.van-cell_title {
	font-size: 16px;
}

.btnDivStyle {
	margin-top: 0.7rem;
	margin-bottom: 0.1rem;
	text-align: center;
}


.form_title {
	padding: 00.3rem;

}

/* newHome  涨幅咨询（4个）*/


.newTitleTwo {
	background: #f6f8f9;
	padding: 0.2rem;
	height: 0.9rem;
	line-height: 0.52rem;
}

.newTitleTwo span {
	font-weight: bold;
}

.newTitleTwo span img {
	width: 0.4rem;
	vertical-align: -0.08rem;
}

/*.newTitleTwo i:before{*/
/*    color: #666;*/
/*    vertical-align: 0;*/
/*    margin-right: 0.1rem;*/
/*    font-size: 19px;*/
/*}*/
.titleOld {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}

.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}

.titleOldName {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}

.titleOldArrow {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

.bodyStyleDivStyleP .van-dropdown-menu__bar {
	box-shadow: none;
}

.bodyStyleDivStyleP .van-dropdown-menu__item {
	justify-content: left;
}

.bodyStyleDivStyleP .van-dropdown-menu__title {
	padding-left: 0;
}

/* ------20200409 start------ */
body {
	font-family: 'PingFangSC-Regular', 'PingFang SC', "微软雅黑", "Microsoft Yahei";
	background-image: url(../imgs/common_bg.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 3.5rem;
}
.bgGray {
	background-color: #F5F5F5 !important;
}

.bgWhite {
	background: #FFFFFF;
}

.tabBox {
	min-height: 50vh;
	padding: 0 .3rem;
}

.van-tab {
	border-bottom: 1px solid #EDEDED;
}

.van-tabs__line {
	background-color: #90C4FF !important;
}

.van-tab--active span {
	font-weight: bold !important;
}

.selected-flag {
	height: .88rem;
}

.van-checkbox__icon {
	height: .4rem;
	line-height: .4rem;
}

.van-checkbox__label {
	line-height: .4rem;
}

.van-overlay {
	background-color: rgba(4, 4, 15, 0.4);
}

.van-popup--bottom {
	padding: 0 15px 10px;
	background: none;
}

.van-action-sheet__content,
.van-action-sheet__cancel {
	border-radius: 10px;
}

.van-action-sheet__item {
	border-bottom: 1px solid rgba(63, 63, 63, 0.09);
}

.van-action-sheet__item,
.van-action-sheet__cancel {
	color: #373737;
	font-size: 16px;
	height: 56px;
	line-height: 56px;
	padding: 0;
	background: rgba(248, 248, 248, 0.82);
}

.van-action-sheet__cancel {
	background: #FFFFFF;
}

.van-action-sheet__gap {
	background: none;
}

.tabVanStyle2 .van-tab {
	font-size: 14px;
}

.tabVanStyle2 .van-tab--active {
	font-size: 16px;
}

.tabVanStyle2 .van-tabs__nav--complete {
	padding: 0;
}
.tabVanStyle2 .van-tabs__line {
	bottom: 0;
}

.van-list__loading, .van-list__finished-text, .van-list__error-text {
	font-size: 14px;
}

.searchBox .van-field__left-icon {
	display: flex;
	align-items: center;
}
.searchBox .van-field__body,
.searchBox .van-field__control {
	height: 100%;
	font-size: .28rem;
}

.robotSetup .van-radio__label {
	padding: 0;
	line-height: 1;
}
.robotSetup .van-radio__icon {
	height: .32rem;
}
.icon_arrR {
	width: .2rem;
	height: .2rem;
	border-top: .04rem solid #D1D1D6;
	border-right: .04rem solid #D1D1D6;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	display: inline-block;
}
.icon_arrL {
	width: .28rem;
	height: .28rem;
	border-top: .04rem solid #fff;
	border-left: .04rem solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	display: inline-block;
}
.list-cell .van-dropdown-menu {
	position: relative;
	z-index: 2;
}
.list-cell .van-dropdown-menu__bar {
	height: .88rem;
	background: none;
	box-shadow: none;
}
.list-cell .van-dropdown-menu__item {
	justify-content: flex-start;
}
.list-cell .van-dropdown-menu__title {
	font-size: .28rem;
}
.list-cell .van-dropdown-menu__title::after,
.list-cell .van-overlay{
	display: none;
}
.list-cell .van-popup {
	background: none;
}
.list-cell .van-cell {
	font-size: .32rem;
	line-height: .65rem;
	width: auto;
	margin: 0 .3rem;
}
.contWhiteBox {
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: .25rem;
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: .88rem;
	line-height: .88rem;
	background: #90C4FF;
	border-radius: .12rem;
}
.comBtnStyle.disabled {
	background: #D1D1D6 !important;
}

.list-radio-item .van-radio__icon {
	height: .32rem;
}
.labelCheck .van-checkbox__icon {
	height: .32rem;
}
.labelCheck .van-checkbox__label {
	margin-left: .1rem;
}
.van-button--normal {
	padding: 0;
}
/* ------20200409 end------ */
